
import CustomInput from "@/components/CustomInput.vue";
import Captcha from "@/components/Captcha.vue";
import CustomButton from "@/components/CustomButton.vue";
import AgreementMessage from "@/components/AgreementMessage.vue";

import { confirmEmail, postRegister } from "@/services/authService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  components: { CustomInput, Captcha, CustomButton, AgreementMessage },
  data() {
    return {
      page: 0,
      username: "",
      email: "",
      password: "",
      confirmEmail: "",
      errors: {} as any,
      requestSent: false,
    };
  },
  watch: {
    confirmEmail: {
      handler: "onEmailConfirmInput",
    },
  },
  methods: {
    onEmailConfirmInput() {
      const value = this.confirmEmail.trim();
      if (value.length !== 10) return;
      confirmEmail(this.email, value)
        .then((data) => {
          localStorage.clear();
          localStorage["hauthid"] = data.token;
          location.href = "/app";
        })
        .catch((err) => {
          if (!err.response) return;
          return err.response.json();
        })
        .then((res) => {
          if (!res)
            return (this.errors["other"] = "Unable to connect to server");
          if (!res.error) return;
          return (this.errors["email_confirm"] = res.error);
        });
    },
    keyDownEvent(event: KeyboardEvent) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.formSubmit();
      }
    },
    captchaSubmit(token: string) {
      this.register(token);
    },
    register(token?: string) {
      if (this.requestSent) return;
      this.requestSent = true;
      const email = this.email;
      const username = this.username;
      const password = this.password;
      postRegister(username, email, password, token)
        .then((data) => {
          localStorage.clear();
          localStorage["hauthid"] = data.token;
          location.href = "/app";
        })
        .catch((err) => {
          this.page = 0;
          if (!err.response) {
            this.errors["other"] = "Unable to connect to server";
            return;
          }
          return err.response.json();
        })
        .then((res) => {
          if (!res) return;
          if (!res.errors) return;
          if (res.errors[0].code === 1) {
            this.page = 1;
            return;
          }
          const errors: any = {};
          for (let i = 0; i < res.errors.length; i++) {
            const error = res.errors[i];
            if (
              error.param === "email" ||
              error.param === "password" ||
              error.param === "username"
            ) {
              errors[error.param] = error.msg;
              continue;
            }
            errors["other"] = error.msg;
          }
          this.errors = errors;
        })
        .finally(() => (this.requestSent = false));
    },
    formSubmit() {
      this.errors = {};
      this.register();
    },
  },
});
