
import { defineComponent } from "vue";
export default defineComponent({
  name: "AgreementMessage",
  props: {
    prefix: {
      type: String,
      required: true,
    },
  },
});
